const carbo_kj = 17;
const carbo_kcal = 4;

const polyols_kj = 10;
const polyols_kcal = 2.4;

const alcohol_kj = 29;
const alcohol_kcal = 7;

const organic_acid_kj = 13;
const organic_acid_kcal = 3;
const organic_acid_kcal_old = 4;

const formulaChangedAt = new Date(2025, 2, 19, 12).getTime();

export const EnergyService = {
  calculateEnergyKcal(alcohol, sugar, acid, polyols, createdAt) {
    if (alcohol === "" || sugar === "" || acid === "" || polyols === "") {
      return "";
    }

    const createdAtTimestamp = createdAt ? new Date(createdAt).getTime() : new Date().getTime();
    const useNewFormula = (createdAtTimestamp > formulaChangedAt) ;


    let res;

    if (useNewFormula) {
      res = Math.ceil(
        (alcohol * alcohol_kcal * 10 * 0.789 +
          sugar * carbo_kcal +
          acid * organic_acid_kcal +
          polyols * polyols_kcal) /
          10
      );
    } else {
      res = Math.ceil(
        (alcohol * alcohol_kcal * 100 * 0.789 +
          sugar * carbo_kcal +
          acid * organic_acid_kcal_old +
          polyols * polyols_kcal) /
          100
      );
    }
    
    // empty if nan
    if (isNaN(res)) {
      return "";
    }
    return res;
  },

  calculateEnergyKj(alcohol, sugar, acid, polyols, createdAt) {
    if (alcohol === "" || sugar === "" || acid === "" || polyols === "") {
      return "";
    }

    const createdAtTimestamp = createdAt ? new Date(createdAt).getTime() : new Date().getTime();
    const useNewFormula = (createdAtTimestamp > formulaChangedAt) ;

    let res;

    if (useNewFormula) {
      res = Math.ceil(
        (alcohol * alcohol_kj * 10 * 0.789 +
          sugar * carbo_kj +
          acid * organic_acid_kj +
          polyols * polyols_kj) /
          10
      );
    } else {
      res = Math.ceil(
        (alcohol * alcohol_kj * 100 * 0.789 +
          sugar * carbo_kj +
          acid * organic_acid_kj +
          polyols * polyols_kj) /
          100
      );
    }

    // empty if nan
    if (isNaN(res)) {
      return "";
    }
    return res;
  },
};

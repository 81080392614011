<script setup>
import { Sortable } from "sortablejs-vue3";

const emit = defineEmits();
const props = defineProps({
  options: {
    type: Array,
    required: true,
    default: () => [],
  },
  selected: {
    type: Array,
    required: false,
    default: () => [],
  },
  disableSorting: {
    type: Boolean,
    required: false,
    default: false,
  },
});

const initialSortedOptionsList = ref([]);

// Track sorted positions and update model value accordingly
const sortedOptions = ref([]);

watch(
  () => initialSortedOptionsList.value,
  () => {
    sortedOptions.value = initialSortedOptionsList.value;
  },
);

watch(
  () => sortedOptions.value,
  () => {},
);

const moveItem = (from, to) => {
  const clonedArray = [...sortedOptions.value];

  const item = clonedArray.splice(from, 1)[0];

  clonedArray.splice(to, 0, item);

  sortedOptions.value = clonedArray;
};

onMounted(() => {
  const selected = props.options
    .filter((option) =>
      props.selected.find((preselected) => preselected === option.value),
    )
    .sort((a, b) => {
      return (
        props.selected.findIndex((item) => item === a.value) -
        props.selected.findIndex((item) => item === b.value)
      );
    });

  const unselected = props.options.filter(
    (option) =>
      !props.selected.find((preselected) => preselected === option.value),
  );

  initialSortedOptionsList.value = [...selected, ...unselected];
  // initialSortedOptionsList.value = props.options;
});

// emit after render
onMounted(() => {
  emit("update", refValues.value);
});

const refValues = ref(props.selected);

function toggleSelection(value) {
  const values = refValues.value;
  if (values.includes(value)) {
    // Deselect the button if it's already selected
    const index = values.indexOf(value);
    values.splice(index, 1);
  } else {
    // Select the button if it's not selected
    values.push(value);
  }
  refValues.value = values;

  emitSortedValues();
}

watch(
  () => sortedOptions.value,
  () => {
    emitSortedValues();
  },
);

const emitSortedValues = () => {
  const selectedItems = refValues.value;

  selectedItems.sort((a, b) => {
    return (
      sortedOptions.value.findIndex((item) => item.value === a) -
      sortedOptions.value.findIndex((item) => item.value === b)
    );
  });

  emit("update", selectedItems);
};

const sortingOptions = {
  animation: 150,
  scroll: true,
  forceFallback: false,
  bubbleScroll: false,
  ghostClass: "ghost",
  disabled: props.disableSorting || props.options.length < 2,
};
</script>

<template>
  <Sortable
    :list="initialSortedOptionsList"
    item-key="value"
    tag="div"
    :options="sortingOptions"
    @update="(e) => moveItem(e.oldIndex, e.newIndex)"
  >
    <template #item="{ element, index }">
      <button
        type="button"
        class="ring-offset-background focus-visible:ring-2 focus-visible:ring-ring transition-shadow custom-button"
        @click.prevent="toggleSelection(element.value)"
        :class="{ selected: refValues.includes(element.value) }"
      >
        {{ element.name }}
      </button>
    </template>
  </Sortable>
</template>

<style scoped>
.buttons-wrapper {
  padding: -5px;
}
/* Style the custom button */
.custom-button {
  border-radius: 6px;
  margin: 5px;
  display: inline-block;
  padding: 10px 20px;
  border: 2px solid #757484;
  background-color: white;
  color: black;
  cursor: pointer;
  outline: none;
}

/* Style the selected state of the custom button */
.custom-button.selected {
  background: #181649;
  border: 2px solid #181649;
  color: white;
}

.ghost {
  opacity: 50%;
  border-style: dashed;
}
</style>

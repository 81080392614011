<script setup>
import ImageUploader from "~/components/ImageUploader.vue";
import NutritionalInformation from "~/components/label/NutritionalInformation.vue";
import RecyclingInformation from "~/components/label/RecyclingInformation.vue";
import { useCurrentUser } from "vuefire";
import { EnergyService } from "~/service/EnergyService";
import { toTypedSchema } from "@vee-validate/zod";

import { useI18n } from "vue-i18n";

import * as z from "zod";

import { Input } from "@/components/ui/input";
import { Checkbox } from "@/components/ui/checkbox";

import {
  FormControl,
  FormDescription,
  FormField,
  FormItem,
  FormLabel,
  FormMessage,
} from "@/components/ui/form";
import {
  Select,
  SelectContent,
  SelectGroup,
  SelectItem,
  SelectTrigger,
  SelectValue,
} from "@/components/ui/select";
import { Tabs, TabsList, TabsTrigger } from "@/components/ui/tabs";
import { Button } from "@/components/ui/button";
import { Collapsible, CollapsibleContent } from "@/components/ui/collapsible";
import { Notice } from "~/components/ui/notice";

const { $intercom } = useNuxtApp();
const { cookiesEnabledIds } = useCookieControl();

const { getIngredientsByType, isIngredientDataFetched } = useIngredientData();

const {
  packagingTypes,
  materialCategories,
  recyclingMethods,
  defaultRecyclingRow,
  getMaterialFromId,
  getSimpleMaterialsFromCategoryId,
  getCompositeMaterialsFromCategoryId,
  getFirstMaterialFromCategoryId,
  isRecyclingDataFetched,
} = useRecyclingData();
const { getRecyclingTranslation, isRecyclingTranslationsFetched } =
  useRecyclingTranslation();

const { isLabelTranslationsFetched, getLabelTranslation } =
  useLabelTranslation();

//set default value for props wine
const props = defineProps({
  wine: {
    type: Object,
    default: () => {
      return {
        name: "",
        vintage: new Date().getFullYear().toString(),
        variety: "",
        alcohol: "",
        sugar: "",
        acid: "",
        polyols: "7",
        energy_kj: "",
        energy_kcal: "",
        ingredients: [],
        allergens: [],
        ingredientsRaw: [],
        ingredientsEnrichments: [],
        ingredientsPreservative: [],
        ingredientsAcidity: [],
        ingredientsStabilisers: [],
        ingredientsPackagingGazes: [],
        ingredientsLiqueur: [],
        ingredientsTirage: [],
        ingredientsAlcohol: [],
        ingredientsExpedition: [],
        ingredientsOther: [],
        allergens: [],
        withManualCalculations: false,
        withRecyclingInformation: false,
        recyclingInformation: [],
      };
    },
  },
  loading: {
    type: Boolean,
    default: false,
  },
  variant: { required: false, default: "primary" },
  size: { required: false, default: "default" },
  brand: { required: true },
  source: { required: false, default: "" },
});

const emit = defineEmits();

onMounted(() => {
  if (props.source === "create") {
    $intercom.trackEvent("Label creation form opened");
  }
});

const formSchema = toTypedSchema(
  z.object({
    name: z
      .string({ required_error: "wine-modal.name-missing" })
      .min(1, "wine-modal.name-missing"),
    vintage: z
      .string({ required_error: "wine-modal.vintage-missing" })
      .min(1, "wine-modal.vintage-missing"),
    variety: z
      .string({ required_error: "wine-modal.variety-missing" })
      .min(1, "wine-modal.variety-missing"),

    withManualCalculations: z.boolean(),

    // automatic calc fields
    alcohol: z.string().optional(),
    sugar: z.string().optional(),
    acid: z.string().optional(),
    polyols: z.string().optional(),
    energy_kcal: z.string().optional(),
    energy_kj: z.string().optional(),

    //manual calc fields
    energy_kcal_manual: z.string().optional(),
    energy_kj_manual: z.string().optional(),
    fat_manual: z.string().optional(),
    saturated_fat_manual: z.string().optional(),
    carbohydrate_manual: z.string().optional(),
    sugar_manual: z.string().optional(),
    protein_manual: z.string().optional(),
    salt_manual: z.string().optional(),

    ingredientsRaw: z.array(z.string()).optional(),
    ingredientsEnrichments: z.array(z.string()).optional(),
    ingredientsPreservative: z.array(z.string()).optional(),
    ingredientsAcidity: z.array(z.string()).optional(),
    ingredientsStabilisers: z.array(z.string()).optional(),
    ingredientsPackagingGazes: z.array(z.string()).optional(),
    ingredientsLiqueur: z.array(z.string()).optional(), // deprecated
    ingredientsTirage: z.array(z.string()).optional(),
    ingredientsAlcohol: z.array(z.string()).optional(),
    ingredientsExpedition: z.array(z.string()).optional(),
    ingredientsOther: z.array(z.string()).optional(),
    allergens: z.array(z.string()).optional(),
    picture: z.string().optional(),
    imageId: z.string().optional(),

    withRecyclingInformation: z.boolean().optional(),
    recyclingInformation: z
      .array(
        z.object({
          type: z.string(),
          category: z.string(),
          material: z.string(),
          recyclingMethod: z.string(),
        }),
      )
      .optional(),
  }),
);

// from grams/liter to grams/100ml
const toGramsPerServing = (val) => (val / 10).toString();

const sendUsAMessage = (e) => {
  if (cookiesEnabledIds.value && cookiesEnabledIds.value.includes("intercom")) {
    $intercom.showNewMessage();
    e.preventDefault();
  }
};

const wineToInitialValues = (wine) => {
  const withManualCalculations = wine.withManualCalculations || false;
  const withRecyclingInformation = wine.withRecyclingInformation || false;

  const formattedWine = {
    ...wine,
    withManualCalculations,
    withRecyclingInformation,
    recyclingInformation: wine.recyclingInformation?.length
      ? wine.recyclingInformation
      : [defaultRecyclingRow],
  };

  console.log(formattedWine);

  if (withManualCalculations) {
    return {
      ...formattedWine,
      energy_kcal_manual: wine.energy_kcal,
      energy_kj_manual: wine.energy_kj,
      salt_manual: toGramsPerServing(wine.salt),
      protein_manual: toGramsPerServing(wine.protein),
      fat_manual: toGramsPerServing(wine.fat),
      saturated_fat_manual: toGramsPerServing(wine.saturated_fat),
      sugar_manual: toGramsPerServing(wine.sugar),
      carbohydrate_manual: toGramsPerServing(wine.carbohydrate),
      sugar: "",
      polyols: "",
      acid: "",
      alcohol: "",
    };
  }

  return {
    ...formattedWine,
    withManualCalculations: false,
    energy_kcal_manual: "",
    energy_kj_manual: "",
    salt_manual: "",
    protein_manual: "",
    fat_manual: "",
    saturated_fat_manual: "",
    sugar_manual: "",
    carbohydrate_manual: "",
  };
};

const form = useForm({
  validationSchema: formSchema,
  initialValues: wineToInitialValues(props.wine),
  keepValuesOnUnmount: true,
});

watch(
  () => form.values,
  () => console.log(form.values),
);

// Scroll to first error when submission is attempted
watch(
  () => form.submitCount.value,
  () => {
    setTimeout(() => {
      if (Object.keys(form.errors.value).length) {
        console.error(form.errors.value);
        const firstInvalidInput =
          document.querySelector("*[aria-invalid]").parentNode;

        firstInvalidInput.scrollIntoView({ behavior: "smooth" });
      }
    }, 10);
  },
);

// Reset form if wine changes
watch(
  () => props.wine,
  (wine) => {
    form.setValues(wine);
  },
);

const { t, locale } = useI18n(); // use as global scope

const user = await useCurrentUser();

const ingredients = computed(() => {
  return getIngredientsByType(props.brand.showCodes);
});

const published = computed(() => {
  props.wine?.published;
});

const filename = computed(() => {
  function generateRandomID() {
    let res = "";
    for (let i = 0; i < 16; i++) {
      // Generate a random number between 0 and 16.
      let randomNumber = Math.floor(Math.random() * 20);
      // Convert the random number to a hexadecimal string.
      let hexString = randomNumber.toString(16);
      // Return the hexadecimal string.
      res += hexString;
    }
    return res;
  }

  if (!user.value) {
    return null;
  }
  return user.value?.uid + "/bottles/";
});

// from grams/100ml to grams/liter
const toGramsPerLiter = (val) => (val * 10).toString();

// Format form data for submission
const formatWineData = (vals) => {
  const baseProperties = {
    name: vals.name,
    vintage: vals.vintage,
    variety: vals.variety,
    withManualCalculations: vals.withManualCalculations,
    ingredientsRaw: vals.ingredientsRaw,
    ingredientsEnrichments: vals.ingredientsEnrichments,
    ingredientsPreservative: vals.ingredientsPreservative,
    ingredientsAcidity: vals.ingredientsAcidity,
    ingredientsStabilisers: vals.ingredientsStabilisers,
    ingredientsPackagingGazes: vals.ingredientsPackagingGazes,
    ingredientsLiqueur: vals.ingredientsLiqueur, // deprecated
    ingredientsTirage: vals.ingredientsTirage,
    ingredientsAlcohol: vals.ingredientsAlcohol,
    ingredientsExpedition: vals.ingredientsExpedition,
    ingredientsOther: vals.ingredientsOther,
    allergens: vals.allergens,
    picture: vals.picture,
    imageId: vals.imageId,
    withRecyclingInformation: vals.withRecyclingInformation,
    recyclingInformation: vals.withRecyclingInformation
      ? vals.recyclingInformation
      : [],
  };

  if (vals.withManualCalculations) {
    // Store in grams/liter
    return {
      ...baseProperties,
      energy_kcal: vals.energy_kcal_manual,
      energy_kj: vals.energy_kj_manual,
      carbohydrate: toGramsPerLiter(vals.carbohydrate_manual),
      sugar: toGramsPerLiter(vals.sugar_manual),
      fat: toGramsPerLiter(vals.fat_manual),
      saturated_fat: toGramsPerLiter(vals.saturated_fat_manual),
      protein: toGramsPerLiter(vals.protein_manual),
      salt: toGramsPerLiter(vals.salt_manual),
      acid: "",
      polyols: "",
      alcohol: "",
    };
  } else {
    return {
      ...baseProperties,
      energy_kcal: EnergyService.calculateEnergyKcal(
        vals.alcohol,
        vals.sugar,
        vals.acid,
        vals.polyols,
        props.wine.created_at,
      ).toString(),
      energy_kj: EnergyService.calculateEnergyKj(
        vals.alcohol,
        vals.sugar,
        vals.acid,
        vals.polyols,
        props.wine.created_at,
      ).toString(),
      carbohydrate: vals.sugar,
      sugar: vals.sugar,
      alcohol: vals.alcohol,
      acid: vals.acid,
      polyols: vals.polyols,
      fat: "",
      saturated_fat: "",
      protein: "",
      salt: "",
    };
  }
};

// create a list of all years between 2010 and 2030 (descending)
const years = ref([]);

const repopulateYears = () => {
  years.value = [];

  for (let i = 2030; i >= 2010; i--) {
    years.value.push({ value: i.toString(), label: i.toString() });
  }
  years.value.push({ value: "nonvintage", label: t("wine-modal.non-vintage") });
  years.value.push({
    value: "multivintage",
    label: t("wine-modal.multi-vintage"),
  });
};
repopulateYears();

watch(() => locale, repopulateYears);

const setPictureFields = ({ url, imageId }) => {
  form.setFieldValue("picture", url);
  form.setFieldValue("imageId", imageId);
};

const addNewRecyclingRow = (e) => {
  e.preventDefault();

  console.log(form.values);
  const prevRecyclingInformation = form.values.recyclingInformation;

  form.setFieldValue("recyclingInformation", [
    ...prevRecyclingInformation,
    defaultRecyclingRow,
  ]);
};

const removeRecyclingRow = (index) => {
  const prevRecyclingInformation = form.values.recyclingInformation;

  const RecyclingInformation = [
    ...prevRecyclingInformation.slice(0, index),
    ...prevRecyclingInformation.slice(
      index + 1,
      prevRecyclingInformation.length,
    ),
  ];

  form.setFieldValue("recyclingInformation", RecyclingInformation);
};

const updateRecyclingType = (index, val) =>
  updateRecyclingField("type", index, val);

const updateRecyclingCategory = (index, val) => {
  updateRecyclingField("category", index, val);

  const firstMaterial = getFirstMaterialFromCategoryId(val);

  console.log("First material!");
  console.log({ firstMaterial });

  updateRecyclingMaterial(index, firstMaterial);
};

const updateRecyclingMaterial = (index, val) => {
  updateRecyclingField("material", index, val);

  const material = getMaterialFromId(val);

  updateRecyclingMethod(index, material.recyclingMethod || "unsorted");
};

const updateRecyclingMethod = (index, val) =>
  updateRecyclingField("recyclingMethod", index, val);

const updateRecyclingField = (field, index, val) => {
  console.log({ field, index, val });
  const prevRecyclingInformation = form.values.recyclingInformation;

  // Deep clone so i canm utate value
  const recyclingInformation = prevRecyclingInformation.map((row) => ({
    type: row.type,
    material: row.material,
    category: row.category,
    recyclingMethod: row.recyclingMethod,
  }));

  recyclingInformation[index][field] = val;

  console.log(recyclingInformation[index][field]);

  console.log(recyclingInformation);

  form.setFieldValue("recyclingInformation", recyclingInformation);
};

const cleanNumericValue = (val) =>
  val
    .replace(/[^0-9.]/g, "")
    .replace(/(\..*?)\..*/g, "$1")
    .replace(/^0[^.]/, "0");

const onSubmit = form.handleSubmit((values) => {
  console.log("Submit with ", values);
  emit("submitForm", formatWineData(values));
});
</script>

<template>
  <form
    v-if="
      isIngredientDataFetched &&
      isLabelTranslationsFetched &&
      isRecyclingDataFetched
    "
    class="flex flex-col gap-4"
    novalidate
    @submit="onSubmit"
  >
    <h2 class="modal-section-title">
      {{ $t("wine-modal.details-section") }}
    </h2>
    <div class="flex flex-col gap-8">
      <FormField v-slot="{ componentField }" name="name">
        <FormItem>
          <FormLabel>
            {{ $t("wine-modal.name-label") }}
          </FormLabel>
          <FormControl>
            <Input
              type="text"
              placeholder="E.g. 2016 Needle's Eye Pinot Noir"
              v-bind="componentField"
            />
          </FormControl>
          <FormDescription>
            {{ $t("wine-modal.name-description-v2") }}
          </FormDescription>
          <FormMessage />
        </FormItem>
      </FormField>

      <div class="flex gap-8 flex-col md:flex-row [&>*]:flex-1">
        <div class="space-y-8">
          <FormField v-slot="{ componentField }" name="vintage">
            <FormItem>
              <FormLabel>{{ $t("wine-modal.vintage-label") }}</FormLabel>

              <Select v-bind="componentField">
                <FormControl>
                  <SelectTrigger>
                    <SelectValue />
                  </SelectTrigger>
                </FormControl>
                <SelectContent>
                  <SelectGroup>
                    <SelectItem v-for="year in years" :value="year.value">{{
                      year.label
                    }}</SelectItem>
                  </SelectGroup>
                </SelectContent>
              </Select>
              <FormDescription>
                {{ $t("wine-modal.vintage-subtitle") }}
              </FormDescription>
              <FormMessage />
            </FormItem>
          </FormField>
          <FormField
            v-slot="{ value, handleChange }"
            type="checkbox"
            name="vintage"
          >
            <FormItem class="flex flex-row items-start gap-x-3 items-center">
              <FormControl>
                <Checkbox
                  value="Non-vintage"
                  :checked="value === 'nonvintage' || value === 'multivintage'"
                  @update:checked="
                    (e) =>
                      handleChange(
                        e ? 'nonvintage' : new Date().getFullYear().toString(),
                      )
                  "
                />
              </FormControl>
              <div class="leading-none">
                <FormLabel class="font-semibold">{{
                  $t("wine-modal.this-wine-is-non-vintage")
                }}</FormLabel>
                <FormMessage />
              </div>
            </FormItem>
          </FormField>
        </div>

        <FormField v-slot="{ componentField }" name="variety">
          <FormItem>
            <FormLabel>
              {{ $t("wine-modal.variety") }}
            </FormLabel>
            <FormControl>
              <Input
                type="text"
                placeholder="Pinot Noir"
                v-bind="componentField"
              />
            </FormControl>
            <FormDescription>
              {{ $t("wine-modal.variety-subtitle") }}
            </FormDescription>
            <FormMessage />
          </FormItem>
        </FormField>
      </div>

      <FormField v-slot="{ componentField, value }" name="picture">
        <FormItem>
          <FormControl
            ><image-uploader
              v-if="user"
              :image="value"
              :prefix="filename"
              @update:modelValue="setPictureFields"
            ></image-uploader>
          </FormControl>
        </FormItem>
      </FormField>
    </div>

    <h2 class="modal-section-title">
      {{ getLabelTranslation("t_ingredients") }}
    </h2>
    <div class="description">
      {{ $t("wine-modal.ingredients-description-v2") }}
    </div>

    <FormField v-slot="{ value, handleChange }" name="ingredientsRaw">
      <FormItem>
        <h3 class="modal-subsection-title">
          {{ getLabelTranslation("t_ingredients_raw") }}
        </h3>
        <FormControl>
          <selectable-buttons
            :options="ingredients['ingredientsRaw']"
            :selected="value"
            @update="handleChange"
          />
        </FormControl>
      </FormItem>
    </FormField>

    <FormField v-slot="{ value, handleChange }" name="ingredientsEnrichments">
      <FormItem>
        <h3 class="modal-subsection-title">
          {{ getLabelTranslation("t_ingredients_enrichments") }}
        </h3>
        <FormControl>
          <selectable-buttons
            :options="ingredients['ingredientsEnrichments']"
            :selected="value"
            @update="handleChange"
          />
        </FormControl>
      </FormItem>
    </FormField>

    <FormField v-slot="{ value, handleChange, name }" name="ingredientsTirage">
      <FormItem>
        <h3 class="modal-subsection-title">
          {{ getLabelTranslation("t_ingredients_tirage") }}
        </h3>
        <FormControl>
          <selectable-buttons
            :options="ingredients['ingredientsTirage']"
            :selected="value"
            @update="handleChange"
          />
        </FormControl>
      </FormItem>
    </FormField>

    <FormField
      v-slot="{ value, handleChange, name }"
      name="ingredientsExpedition"
    >
      <FormItem>
        <h3 class="modal-subsection-title">
          {{ getLabelTranslation("t_ingredients_expedition") }}
        </h3>
        <FormControl>
          <selectable-buttons
            :options="ingredients['ingredientsExpedition']"
            :selected="value"
            @update="handleChange"
          />
        </FormControl>
      </FormItem>
    </FormField>

    <FormField
      v-if="ingredients['ingredientsAlcohol']?.length"
      v-slot="{ value, handleChange, name }"
      name="ingredientsAlcohol"
    >
      <FormItem>
        <h3 class="modal-subsection-title">
          {{ getLabelTranslation("t_ingredients_alcohol") }}
        </h3>
        <FormControl>
          <selectable-buttons
            :options="ingredients['ingredientsAlcohol']"
            :selected="value"
            @update="handleChange"
          />
        </FormControl>
      </FormItem>
    </FormField>

    <FormField
      v-slot="{ value, handleChange, name }"
      name="ingredientsPreservative"
    >
      <FormItem>
        <h3 class="modal-subsection-title">
          {{ getLabelTranslation("t_ingredients_preservative") }}
        </h3>
        <FormControl>
          <selectable-buttons
            :options="ingredients['ingredientsPreservative']"
            :selected="value"
            @update="handleChange"
          />
        </FormControl>
      </FormItem>
    </FormField>

    <FormField v-slot="{ value, handleChange, name }" name="ingredientsAcidity">
      <FormItem>
        <h3 class="modal-subsection-title">
          {{ getLabelTranslation("t_ingredients_acidity") }}
        </h3>
        <FormControl>
          <selectable-buttons
            :options="ingredients['ingredientsAcidity']"
            :selected="value"
            @update="handleChange"
          />
        </FormControl>
      </FormItem>
    </FormField>

    <FormField
      v-slot="{ value, handleChange, name }"
      name="ingredientsStabilisers"
    >
      <FormItem>
        <h3 class="modal-subsection-title">
          {{ getLabelTranslation("t_ingredients_stabilisers") }}
        </h3>
        <FormControl>
          <selectable-buttons
            :options="ingredients['ingredientsStabilisers']"
            :selected="value"
            @update="handleChange"
          />
        </FormControl>
      </FormItem>
    </FormField>

    <FormField
      v-slot="{ value, handleChange, name }"
      name="ingredientsPackagingGazes"
    >
      <FormItem>
        <h3 class="modal-subsection-title">
          {{ getLabelTranslation("t_ingredients_packaging") }}
        </h3>
        <FormControl>
          <selectable-buttons
            :options="ingredients['ingredientsPackagingGazes']"
            :selected="value"
            @update="handleChange"
          />
        </FormControl>
      </FormItem>
    </FormField>

    <FormField
      v-if="wine.ingredientsLiqueur?.length"
      v-slot="{ value, handleChange, name }"
      name="ingredientsLiqueur"
    >
      <FormItem>
        <h3 class="modal-subsection-title">
          {{ getLabelTranslation("t_ingredients_liqueur") }}
        </h3>
        <FormControl>
          <selectable-buttons
            :options="ingredients['ingredientsLiqueur']"
            :selected="value"
            @update="handleChange"
          />
        </FormControl>
      </FormItem>
    </FormField>

    <FormField v-slot="{ value, handleChange, name }" name="ingredientsOther">
      <FormItem>
        <h3 class="modal-subsection-title">
          {{ getLabelTranslation("t_ingredients_other") }}
        </h3>
        <FormControl>
          <selectable-buttons
            :options="ingredients['ingredientsOther']"
            :selected="value"
            @update="handleChange"
          />
        </FormControl>
      </FormItem>
    </FormField>

    <div class="flex flex-col">
      <h2 class="modal-section-title">
        {{ $t("wine-modal.nutritional") }}
      </h2>
      <div class="description">
        {{ $t("wine-form.nutritional-description") }}
      </div>

      <FormField v-slot="{ value, handleChange }" name="withManualCalculations">
        <Tabs :default-value="value" class="w-full self-stretch mt-6">
          <TabsList class="grid w-full grid-cols-2">
            <TabsTrigger :value="false" @click="handleChange(false)">
              {{ $t("wine-form.automatic") }}
            </TabsTrigger>
            <TabsTrigger :value="true" @click="handleChange(true)">
              {{ $t("wine-form.manual") }}
            </TabsTrigger>
          </TabsList>
        </Tabs>
      </FormField>

      <Collapsible v-model:open="form.values.withManualCalculations">
        <CollapsibleContent>
          <Notice class="mt-4" variant="primary" :fullwidth="true">
            <span v-html="$t('wine-form.manual-description')"></span>
          </Notice>
        </CollapsibleContent>
      </Collapsible>

      <div
        class="grid-cols-2 md:grid-cols-4 grid gap-6 mt-8 mb-8"
        v-if="!form.values.withManualCalculations"
      >
        <div class="column">
          <FormField v-slot="{ componentField, handleChange }" name="alcohol">
            <FormItem>
              <FormLabel>
                {{ $t("wine-modal.alcohol-label") }}
              </FormLabel>
              <FormControl>
                <Input
                  type="text"
                  placeholder="E.g. 13.0"
                  v-bind="componentField"
                  @input="
                    (e) => handleChange(cleanNumericValue(e.target.value))
                  "
                />
              </FormControl>
              <FormDescription>
                {{ $t("wine-modal.percentage") }} %
              </FormDescription>
              <FormMessage />
            </FormItem>
          </FormField>
        </div>
        <div class="column">
          <FormField v-slot="{ componentField, handleChange }" name="sugar">
            <FormItem>
              <FormLabel>
                {{ $t("wine-modal.sugar") }}
              </FormLabel>
              <FormControl>
                <Input
                  type="text"
                  placeholder="E.g. 3.0"
                  v-bind="componentField"
                  @input="
                    (e) => handleChange(cleanNumericValue(e.target.value))
                  "
                />
              </FormControl>
              <FormDescription>
                {{ $t("wine-modal.grams-per-litre") }}
              </FormDescription>
              <FormMessage />
            </FormItem>
          </FormField>
        </div>
        <div class="column">
          <FormField v-slot="{ componentField, handleChange }" name="acid">
            <FormItem>
              <FormLabel>
                {{ $t("wine-modal.organic-acid") }}
              </FormLabel>
              <FormControl>
                <Input
                  type="text"
                  placeholder="E.g. 6.0"
                  v-bind="componentField"
                  @input="
                    (e) => handleChange(cleanNumericValue(e.target.value))
                  "
                />
              </FormControl>
              <FormDescription>
                {{ $t("wine-modal.grams-per-litre") }}
              </FormDescription>
              <FormMessage />
            </FormItem>
          </FormField>
        </div>
        <div class="column">
          <FormField v-slot="{ componentField, handleChange }" name="polyols">
            <FormItem>
              <FormLabel>
                {{ $t("wine-modal.polyols") }}
              </FormLabel>
              <FormControl>
                <Input
                  type="text"
                  placeholder="E.g. 7.0"
                  v-bind="componentField"
                  @input="
                    (e) => handleChange(cleanNumericValue(e.target.value))
                  "
                />
              </FormControl>
              <FormDescription>
                {{ $t("wine-modal.grams-per-litre") }}
              </FormDescription>
              <FormMessage />
            </FormItem>
          </FormField>
        </div>
      </div>

      <div v-else class="grid-cols-2 md:grid-cols-4 grid gap-6 mt-8 mb-8">
        <div class="column">
          <FormField
            v-slot="{ componentField, handleChange }"
            name="energy_kj_manual"
          >
            <FormItem>
              <FormLabel> {{ $t("wine-form.energy") }} </FormLabel>
              <FormControl>
                <Input
                  type="text"
                  placeholder="0.0"
                  v-bind="componentField"
                  @input="
                    (e) => handleChange(cleanNumericValue(e.target.value))
                  "
                />
              </FormControl>
              <FormDescription> {{ $t("wine-form.kj") }} </FormDescription>
              <FormMessage />
            </FormItem>
          </FormField>
        </div>
        <div class="column">
          <FormField
            v-slot="{ componentField, handleChange }"
            name="energy_kcal_manual"
          >
            <FormItem>
              <FormLabel>&nbsp;</FormLabel>
              <FormControl>
                <Input
                  type="text"
                  placeholder="0.0"
                  v-bind="componentField"
                  @input="
                    (e) => handleChange(cleanNumericValue(e.target.value))
                  "
                />
              </FormControl>
              <FormDescription> {{ $t("wine-form.kcal") }} </FormDescription>
              <FormMessage />
            </FormItem>
          </FormField>
        </div>

        <div class="column">
          <FormField
            v-slot="{ componentField, handleChange }"
            name="fat_manual"
          >
            <FormItem>
              <FormLabel> {{ $t("wine-form.fats") }} </FormLabel>
              <FormControl>
                <Input
                  type="text"
                  placeholder="0.0"
                  v-bind="componentField"
                  @input="
                    (e) => handleChange(cleanNumericValue(e.target.value))
                  "
                />
              </FormControl>
              <FormDescription>
                {{ $t("wine-modal.grams-per-serving") }}
              </FormDescription>
              <FormMessage />
            </FormItem>
          </FormField>
        </div>
        <div class="column">
          <FormField
            v-slot="{ componentField, handleChange }"
            name="saturated_fat_manual"
          >
            <FormItem>
              <FormLabel> {{ $t("wine-form.of-which-saturate") }} </FormLabel>
              <FormControl>
                <Input
                  type="text"
                  placeholder="0.0"
                  v-bind="componentField"
                  @input="
                    (e) => handleChange(cleanNumericValue(e.target.value))
                  "
                />
              </FormControl>
              <FormDescription>
                {{ $t("wine-modal.grams-per-serving") }}
              </FormDescription>
              <FormMessage />
            </FormItem>
          </FormField>
        </div>
      </div>
      <Collapsible v-model:open="form.values.withManualCalculations">
        <CollapsibleContent class="mx-[-2px] px-[2px]">
          <div class="grid-cols-2 md:grid-cols-4 grid gap-6 mb-8">
            <div class="column">
              <FormField
                v-slot="{ componentField, handleChange }"
                name="carbohydrate_manual"
              >
                <FormItem>
                  <FormLabel> {{ $t("wine-form.carbs") }} </FormLabel>
                  <FormControl>
                    <Input
                      type="text"
                      placeholder="0.0"
                      v-bind="componentField"
                      @input="
                        (e) => handleChange(cleanNumericValue(e.target.value))
                      "
                    />
                  </FormControl>
                  <FormDescription>
                    {{ $t("wine-modal.grams-per-serving") }}
                  </FormDescription>
                  <FormMessage />
                </FormItem>
              </FormField>
            </div>
            <div class="column">
              <FormField
                v-slot="{ componentField, handleChange }"
                name="sugar_manual"
              >
                <FormItem>
                  <FormLabel>{{ $t("wine-form.of-which-sugars") }}</FormLabel>
                  <FormControl>
                    <Input
                      type="text"
                      placeholder="0.0"
                      v-bind="componentField"
                      @input="
                        (e) => handleChange(cleanNumericValue(e.target.value))
                      "
                    />
                  </FormControl>
                  <FormDescription>
                    {{ $t("wine-modal.grams-per-serving") }}
                  </FormDescription>
                  <FormMessage />
                </FormItem>
              </FormField>
            </div>
            <div class="column">
              <FormField
                v-slot="{ componentField, handleChange }"
                name="protein_manual"
              >
                <FormItem>
                  <FormLabel>{{ $t("wine-form.protein") }}</FormLabel>
                  <FormControl>
                    <Input
                      type="text"
                      placeholder="0.0"
                      v-bind="componentField"
                      @input="
                        (e) => handleChange(cleanNumericValue(e.target.value))
                      "
                    />
                  </FormControl>
                  <FormDescription>
                    {{ $t("wine-modal.grams-per-serving") }}
                  </FormDescription>
                  <FormMessage />
                </FormItem>
              </FormField>
            </div>
            <div class="column">
              <FormField
                v-slot="{ componentField, handleChange }"
                name="salt_manual"
              >
                <FormItem>
                  <FormLabel>{{ $t("wine-form.salt") }}</FormLabel>
                  <FormControl>
                    <Input
                      type="text"
                      placeholder="0.0"
                      v-bind="componentField"
                      @input="
                        (e) => handleChange(cleanNumericValue(e.target.value))
                      "
                    />
                  </FormControl>
                  <FormDescription>
                    {{ $t("wine-modal.grams-per-serving") }}
                  </FormDescription>
                  <FormMessage />
                </FormItem>
              </FormField>
            </div></div
        ></CollapsibleContent>
      </Collapsible>

      <NutritionalInformation
        :title="$t('wine-modal.calculations')"
        :brand="brand"
        :wine="formatWineData(form.values)"
        grey-background
        small-values
        show-serving-size
      />
    </div>

    <FormField v-slot="{ value, handleChange }" name="allergens">
      <FormItem>
        <h3 class="modal-subsection-title">
          {{ getLabelTranslation("t_allergens") }}
        </h3>
        <FormControl>
          <selectable-buttons
            :options="ingredients['ingredientsAllergens']"
            :selected="value"
            @update="handleChange"
            :disable-sorting="true"
          />
        </FormControl>
      </FormItem>
    </FormField>

    <!-- Recycling information -->
    <h3 class="modal-subsection-title">{{ $t("wine-form.recycling") }}</h3>

    <p>
      {{ $t("wine-form.recycling-explanation") }}
    </p>

    <FormField
      v-slot="{ value, handleChange }"
      type="checkbox"
      name="withRecyclingInformation"
    >
      <FormItem class="flex flex-row items-start gap-x-3 items-center">
        <FormControl>
          <Checkbox
            value="true"
            :checked="value"
            @update:checked="handleChange"
          />
        </FormControl>
        <div class="leading-none">
          <FormLabel class="font-semibold">
            {{ $t("wine-form.recycling-subtitle") }}
          </FormLabel>
          <FormMessage />
        </div>
      </FormItem>
    </FormField>

    <Collapsible v-model:open="form.values.withRecyclingInformation">
      <CollapsibleContent>
        <div
          class="p-8 border border-mono-silver rounded-3xl flex flex-col gap-3 mb-5"
        >
          <h4 class="text-mono-black text-lg font-black">
            {{ $t("wine-form.recycling-information") }}
          </h4>

          <p class="text-mono-grey mb-8">
            {{ $t("wine-form.recycling-helper") }}
          </p>

          <FormField
            v-slot="{ value, handleChange }"
            name="recyclingInformation"
          >
            <FormItem>
              <div v-for="(recyclingRow, index) in value" class="w-full">
                <hr v-if="index > 0" class="my-5" />
                <div class="flex flex-row gap-4">
                  <div class="w-1/3">
                    <div class="flex items-center justify-between mb-3">
                      <FormLabel>{{ $t("wine-form.type") }}</FormLabel>
                    </div>
                    <Select
                      :modelValue="recyclingRow.type"
                      @update:modelValue="
                        (type) => updateRecyclingType(index, type)
                      "
                    >
                      <FormControl>
                        <SelectTrigger>
                          <SelectValue />
                        </SelectTrigger>
                      </FormControl>
                      <SelectContent>
                        <SelectGroup>
                          <SelectItem
                            v-for="type in packagingTypes"
                            :value="type"
                          >
                            {{
                              getRecyclingTranslation("packagingTypes", type)
                            }}</SelectItem
                          >
                        </SelectGroup>
                      </SelectContent>
                    </Select>
                  </div>

                  <div class="flex-grow flex flex-col gap-4">
                    <!-- Material Category -->
                    <div>
                      <div class="flex items-center justify-between mb-3">
                        <FormLabel>{{ $t("wine-form.category") }}</FormLabel>
                        <a
                          v-if="index > 0"
                          class="text-destructive hover:text-destructive-dark font-bold flex flex-row items-center gap-2 leading-none"
                          href="#"
                          @click.prevent="removeRecyclingRow(index)"
                        >
                          <font-awesome icon="trash" />
                          {{ $t("wine-form.delete") }}</a
                        >
                      </div>
                      <Select
                        :modelValue="recyclingRow.category"
                        @update:modelValue="
                          (category) => updateRecyclingCategory(index, category)
                        "
                      >
                        <FormControl>
                          <SelectTrigger>
                            <SelectValue />
                          </SelectTrigger>
                        </FormControl>
                        <SelectContent>
                          <SelectGroup>
                            <SelectItem
                              v-for="category in materialCategories"
                              :value="category.id"
                            >
                              {{
                                getRecyclingTranslation(
                                  "materialCategories",
                                  category.id,
                                )
                              }}</SelectItem
                            >
                          </SelectGroup>
                        </SelectContent>
                      </Select>
                    </div>

                    <!-- Material -->
                    <div>
                      <div class="flex items-center justify-between mb-3">
                        <FormLabel>{{ $t("wine-form.material") }}</FormLabel>
                      </div>
                      <Select
                        :modelValue="recyclingRow.material"
                        @update:modelValue="
                          (material) => updateRecyclingMaterial(index, material)
                        "
                      >
                        <FormControl>
                          <SelectTrigger>
                            <SelectValue />
                          </SelectTrigger>
                        </FormControl>
                        <SelectContent>
                          <SelectGroup>
                            <SelectItem
                              v-for="material in getSimpleMaterialsFromCategoryId(
                                recyclingRow.category,
                              )"
                              :value="material.id"
                            >
                              {{
                                [
                                  [
                                    material.abbreviation,
                                    material.recyclingCode,
                                  ]
                                    .filter((part) => !!part)
                                    .join(" "),
                                  ,
                                  getRecyclingTranslation(
                                    "materials",
                                    material.id,
                                  ),
                                ]
                                  .filter((part) => !!part)
                                  .join(" - ")
                              }}</SelectItem
                            >
                          </SelectGroup>

                          <SelectLabel
                            v-if="
                              getCompositeMaterialsFromCategoryId(
                                recyclingRow.category,
                              ).length
                            "
                            >{{ $t("wine-form.composites") }}</SelectLabel
                          >

                          <SelectGroup>
                            <SelectItem
                              v-for="material in getCompositeMaterialsFromCategoryId(
                                recyclingRow.category,
                              )"
                              :value="material.id"
                            >
                              {{
                                [
                                  [
                                    material.abbreviation,
                                    material.recyclingCode,
                                  ]
                                    .filter((part) => !!part)
                                    .join(" "),
                                  ,
                                  getRecyclingTranslation(
                                    "materials",
                                    material.id,
                                  ),
                                ]
                                  .filter((part) => !!part)
                                  .join(" - ")
                              }}</SelectItem
                            >
                          </SelectGroup>
                        </SelectContent>
                      </Select>
                    </div>

                    <!-- Recycling type -->
                    <div
                      v-if="
                        !getMaterialFromId(recyclingRow.material)
                          .recyclingMethod
                      "
                    >
                      <div class="flex items-center justify-between mb-3">
                        <FormLabel>{{
                          $t("wine-form.recycling-method")
                        }}</FormLabel>
                      </div>
                      <Select
                        :modelValue="recyclingRow.recyclingMethod"
                        @update:modelValue="
                          (recyclingMethod) =>
                            updateRecyclingMethod(index, recyclingMethod)
                        "
                      >
                        <FormControl>
                          <SelectTrigger>
                            <SelectValue />
                          </SelectTrigger>
                        </FormControl>
                        <SelectContent>
                          <SelectGroup>
                            <SelectItem
                              v-for="method in recyclingMethods"
                              :value="method.id"
                            >
                              <span class="flex flex-row items-center gap-2">
                                <span
                                  class="block w-3 h-3 rounded-full"
                                  :style="{ background: method.color }"
                                ></span>
                                {{
                                  getRecyclingTranslation(
                                    "recyclingMethods",
                                    method.id,
                                  )
                                }}
                              </span>
                            </SelectItem>
                          </SelectGroup>
                        </SelectContent>
                      </Select>
                    </div>
                  </div>
                </div>
              </div>
            </FormItem>
          </FormField>

          <Button
            class="my-4"
            variant="secondary-outlined"
            size="lg"
            @click.prevent="addNewRecyclingRow"
            ><font-awesome icon="plus" size="lg" class="mr-1"></font-awesome
            >{{ $t("wine-form.add-another") }}</Button
          >

          <Notice variant="primary" :fullwidth="true">
            {{ $t("wine-form.missing-material") }} –
            <a
              class="text-primary font-bold hover:text-primary-dark"
              href="mailto:team@pinotqr.com"
              @click="sendUsAMessage"
              >{{ $t("wine-form.send-message") }}</a
            >
          </Notice>
        </div>

        <RecyclingInformation
          :recyclingInformation="form.values.recyclingInformation"
          :grey-background="true"
      /></CollapsibleContent>
    </Collapsible>

    <div class="bg-mono-light-silver rounded-xl p-8 mt-8" :withDivider="false">
      <Alert fullwidth class="mb-8">
        <span v-if="!published">
          {{ $t("wine-modal.draft") }}
        </span>
        <span v-else>
          {{ $t("wine-modal.published") }}
        </span>
      </Alert>

      <div class="flex justify-between flex-col sm:flex-row gap-6">
        <Button
          type="button"
          size="lg"
          variant="secondary-outlined"
          @click.prevent="$emit('cancel')"
        >
          {{ $t("edit-brand.discard") }}
        </Button>
        <Button size="lg" type="submit" :loading="loading">
          {{ $t("edit-brand.save") }}
        </Button>
      </div>
    </div>
  </form>
</template>
